import React, { useState, useEffect } from 'react';
import { useAccount, WagmiConfig, createClient, useContract } from 'wagmi';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { ConnectButton, RainbowKitProvider, getDefaultWallets, darkTheme, wallet } from '@rainbow-me/rainbowkit';
import merge from 'lodash.merge';
import {  chain, configureChains } from 'wagmi';
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import "@rainbow-me/rainbowkit/styles.css";
import wine from './wine.png';
import wine2 from './wine2.png';
import wineContainer from './wineContainer.jpg';
import logo from './logo.svg';
import './App.css';
import ABI from './abi.json'
import { useNavigate, useLocation, Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Claim from './Claim'; // assuming Claim.js is in the same directory as App.js
import { polygon, sepolia } from 'viem/chains';





const { chains, provider } = configureChains(
  [polygon],
  [alchemyProvider({ alchemyId: process.env.REACT_APP_ALCHEMY_ID }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

function WalletInfo() {
  const account = useAccount();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [mintAmount, setMintAmount] = useState(1); // Initial mint amount
  const [success, setSuccess] = useState()

  const navigate = useNavigate();


  
  
  const handleDecrement = () => {
    if (mintAmount > 1) {
      setMintAmount(mintAmount - 1);
    }
  };

  const handleIncrement = () => {
    if (mintAmount < 12) {
      setMintAmount(mintAmount + 1);
    }
  };

  const contractAddress = '0x6e935719c085ab5e6150f5250236d6caca103a29';

  const mintNFT = async () => {
    try {
      const contractAddress = '0x6e935719c085ab5e6150f5250236d6caca103a29'; 
      if (!account || !account.connector) {
        alert('Please connect your wallet first.');
        return;
      }
      const signer = await account.connector.getSigner();
      if (!signer) {
        alert('Unable to get signer from the connected wallet.');
        return;
      }
      const contract = new ethers.Contract(contractAddress, ABI, signer);
  
  
      // transaction overrides
      const transactionOverrides = {
        gasLimit: ethers.utils.hexlify(1000000), // 1 million gas limit, adjust as necessary
      };
  
      let transaction;
  
      
      const priceInWei = ethers.utils.parseEther("175");
const totalValue = priceInWei.mul(ethers.BigNumber.from(mintAmount));

transaction = await contract.mint(mintAmount, {
  ...transactionOverrides,
  value: totalValue, // Adding the value to the transaction overrides
});  
  
  
      await transaction.wait();
      setSuccess(true)
    } catch (error) {
      console.error('An error occurred!', error);
  }
  };

  

  useEffect(() => {
    if (account.address) {
      setIsWalletConnected(true);
    }
  }, [account.address]);



   return (
    <div className="image-container2" >
      <img src={wine} alt="wine" className="right-side-image" />
      <ConnectButton label="Buy your NFT" accountStatus={"address"} chainStatus="none" showBalance={false}/>
      

      {isWalletConnected ? <div><div className="slider-container">
        <div className="slider-value">{mintAmount}</div>
        <input
          className="mint-slider"
          type="range"
          min="1"
          max="10"
          value={mintAmount}
          onChange={event => setMintAmount(Number(event.target.value))}
        />
      </div> {success ? <h3>NFT Minted</h3> : <button className='header-button' style={{marginTop:'10px'}} onClick={mintNFT}>Confirm</button>}</div>:''}
      
     
    </div>
  
  );
}

function App2() {
  const navigate = useNavigate();
  const goToClaimPage = () => {
    navigate('/claim');
  }
 
  const [selectedOption, setSelectedOption] = useState('EN');
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const options = ['EN', 'ES', 'PT']; // Define your options here




  return (
    
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider theme={darkTheme({
      accentColor: '#A32036',
      accentColorForeground: 'white',
      borderRadius: 'none',
      fontStack: 'rounded',
      overlayBlur: 'small',
    })}  chains={chains}>
     
      {selectedOption === 'EN' &&  <section><div className="app" style={{marginTop:'20px'}}>
      <header className="app-header">
      <a href="https://www.vikwine.com/" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="logo" />
          </a>
          
        <div style={{ position: 'relative' }}>
            <button className="dropdown-button" onClick={() => setIsOpen(!isOpen)}>
              {selectedOption || 'EN'}
            </button>
            {isOpen && (
              <ul className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
                {options.map((option, index) => (
                  <li key={index} onClick={() => handleOptionSelect(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            )}
            <button className='header-button' style={{ padding:'10px 45px', borderRadius:'5px', marginLeft:'10px', fontWeight:'500'}} onClick={goToClaimPage}>Claim</button>
          </div>

      </header>
        <main className="main-content">
          <div className="text-container" >
            <h1 className="title">VIK</h1>
            <h2 className="subtitle">NFT VIK Chile Winery <br></br>2023 Collection</h2>
            <h3 >Non-fungible tokens (NFTs) seem to be everywhere these days. From art and music to tweets and videos,
              these digital assets are part of the future of commerce, which is ironically, already here.<br></br><br></br>
              While some say they're a mainstream trend, others believe NFTs are here to stay, and that they will
              change investing forever.<br></br><br></br>
              For those who aren't as familiarized with Non-Fungible Tokens, in short these are digital assets that
              represents both tangible and intangible elements, in this case, our exclusive wine. Each NFT has a unique
              signature that proves its authenticity, increasing its appeal in industries with high collectible value. Said
              “non-fungibility” implies that they cannot be eliminated or exchanged for another good, unlike
              cryptocurrencies, or money, which can be exchanged between equal units.<br></br><br></br>
              Market forecasts predict Luxury NFTs could become a $56 billion market by 2030, which is why this
              limited edition NFT VIK 2018 is part of our efforts to translate creativity and high-class quality in to
              everything we do.
            </h3>
          </div>
          
       
   <WalletInfo/>
        
        </main>
      </div>
      <div className="app" >
        <main className="main-content"  style={{ backgroundColor:'#A32036', padding:'20px', borderRadius:'10px'}}>
        <div className="image-container">
            <img src={wine2} alt="wine" className="right-side-image" style={{width:'500px', marginLeft:'50px', marginTop:'50px'}} />
          </div>
          <div className="text-container">
            <h3 style={{color:'white', marginTop:'50px', marginRight:'50px'}}>Unlike most digital goods, NFTs create digital scarcity, standing as unrepeatable goods with high intrinsic
                value based on their rarity, exclusivity, and price. These characteristics are consistent with the high value
                that VIK products represent.<br></br><br></br>
                As our core values show, VIK's central impulse relies on innovation. Whereas in our production
                processes or our go-to market approach, we pride ourselves in being disruptive and finding new ways to
                bring you the very best of our unique products.
               
            </h3>
          </div>
          
          
        </main>
        <main className="main-content"  style={{ backgroundColor:'#A32036', padding:'20px', borderRadius:'10px', marginTop:'-20px'}}>
        
          <div className="text-container" style={{marginTop:'20px'}}>
            <h3 style={{color:'white', marginTop:'0px', width:'500px', marginRight:'70px', marginLeft:'50px'}}>
                This time, we decided to take that approach one step forward in our journey to increasingly incorporate
                new technologies as part of our product portfolio, which is why 2023 marks the launching of our first ever
                NFT by VIK. After all, &quot;Science and knowledge are our foundation, passion our engine and wine the
                expression of our art.&quot;<br></br><br></br>
                To mark this milestone and to celebrate VIK Winery being awarded the 4 th place in World's Best
                Vineyards 2022, we have selected our VIK 2018 edition '' rated 98 points by James Suckling.<br></br><br></br>
                This unique blend of 75% Cabernet Sauvignon and 25% Cabernet Franc, was crafted under the watchful
                eye of Chief Winemaker Cristián Vallejo and aged for 26 months in French oak barrels. Sustainably
                produced in Chile's Millahue “Golden Valley”, it embodies a dynamic, elegant, pure, yet tense and
                vibrant wine. VIK 2018 represents the most authentic and refined expression of our terroir.
            </h3>
          </div>
          <div className="image-container" style={{marginTop:'20px'}}>
            <img src={wineContainer} alt="wine" className="right-side-image" style={{width:'550px', marginLeft:'70px', marginBottom:'290px'}} />
          </div>
          
        </main>
        </div>
        <div className="app" >
        <main className="main-content" >
        
          <div className="text-container" style={{marginTop:'0px'}}>
            <h3 >Standing out as a unique opportunity to purchase a collectible edition of VIK wines, this vintage will only
                be sold in an NFT format and cannot be found in any store as retail public purchase is not available.<br></br><br></br>
                Thanks to our NFT exchange platform, this digital asset represents a redeemable token, which can be
                cashed for a physical bottle of VIK 2018, and whose maximum amount cannot be copied or tampered
                with.<br></br><br></br>
                With this, we seek to be a pioneer winery in our country and Latin America, in accordance with the
                standards of excellence that characterize our products and the work of all those who work with us.<br></br><br></br>
                The launch will be on our portal nft.vikwines.com and the cost for each NFT will be 175 MATIC.<br></br><br></br>
                Mark our words, this, is just the beginning. Our goal is not to limit ourselves to a single point of sale,
                aiming to be a multi-channel distributor, reaching new collectors and wine enthusiasts, both for our global
                loyal customers, and those embedded in this new digital age.<br></br><br></br>
                At VIK, we make future.
            </h3>
          </div>
         
          
        </main>
        </div> </section>}
        {selectedOption === 'ES' &&  <section><div className="app" style={{marginTop:'20px'}}>
      <header className="app-header">
      <a href="https://www.vikwine.com/" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="logo" />
          </a>
        <div style={{ position: 'relative' }}>
            <button className="dropdown-button" onClick={() => setIsOpen(!isOpen)}>
              {selectedOption || 'ES'}
            </button>
            {isOpen && (
              <ul className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
                {options.map((option, index) => (
                  <li key={index} onClick={() => handleOptionSelect(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            )}<button className='header-button' style={{ padding:'10px 45px', borderRadius:'5px', marginLeft:'10px', fontWeight:'500'}} onClick={goToClaimPage}>Claim</button>
          </div>
      </header>
        <main className="main-content">
          <div className="text-container">
            <h1 className="title">VIK</h1>
            <h2 className="subtitle">NFT VIK Chile Winery <br></br>2023 Collection</h2>
            <h3>

              Los tokens no fungibles (NFT) parecen estar en todas partes en estos días. Desde arte y música hasta
tweets y videos, estos activos digitales son parte del futuro del comercio que, irónicamente, ya está aquí.<br></br><br></br>
Mientras que algunos dicen que son una tendencia generalizada, otros creen que los NFT llegaron para
quedarse y que cambiarán la inversión para siempre.<br></br><br></br>
Para los que no estén tan familiarizados con los Non-Fungible Tokens, en definitiva se trata de activos
digitales que representan tanto elementos tangibles como intangibles, en este caso, nuestro vino exclusivo.
Cada NFT tiene una firma única que prueba su autenticidad, aumentando su atractivo en industrias con
alto valor coleccionable. Dicha “no fungibilidad” implica que no pueden ser eliminadas ni canjeadas por
otro bien, a diferencia de las criptomonedas, o el dinero, que sí puede ser canjeado entre unidades iguales.<br></br><br></br>
Las previsiones del mercado predicen que los NFT de lujo podrían convertirse en un mercado de $ 56 mil
millones para 2030, razón por la cual esta edición limitada NFT VIK 2018 es parte de nuestros esfuerzos
para traducir la creatividad y la calidad de primera clase en todo lo que hacemos.
            </h3>
          </div>
          
       
   <WalletInfo/>
        
        </main>
      </div>
      <div className="app" >
        <main className="main-content"  style={{ backgroundColor:'#A32036', padding:'20px', borderRadius:'10px'}}>
        <div className="image-container">
            <img src={wine2} alt="wine" className="right-side-image" style={{width:'500px', marginLeft:'50px', marginTop:'50px'}} />
          </div>
          <div className="text-container">
            <h3 style={{color:'white', marginTop:'50px', marginRight:'50px'}}>

                A diferencia de la mayoría de los bienes digitales, los NFT crean escasez digital y se presentan como
bienes irrepetibles con un alto valor intrínseco en función de su rareza, exclusividad y precio. Estas
características son consistentes con el alto valor que representan los productos VIK.<br></br><br></br>
Como muestran nuestros valores fundamentales, el impulso central de VIK se basa en la innovación.
Mientras que en nuestros procesos de producción o nuestro enfoque de comercialización, nos
enorgullecemos de ser disruptivos y encontrar nuevas formas de brindarle lo mejor de nuestros productos
únicos.
               
            </h3>
          </div>
          
          
        </main>
        <main className="main-content"  style={{ backgroundColor:'#A32036', padding:'20px', borderRadius:'10px', marginTop:'-20px'}}>
        
          <div className="text-container" style={{marginTop:'20px'}}>
            <h3 style={{color:'white', marginTop:'0px', width:'500px', marginRight:'70px', marginLeft:'50px'}}>

                Esta vez, decidimos llevar ese enfoque un paso más adelante en nuestro viaje para incorporar cada vez
más nuevas tecnologías como parte de nuestra cartera de productos, razón por la cual 2023 marca el
lanzamiento de nuestro primer NFT de VIK. Después de todo, &quot;La ciencia y el conocimiento son nuestra
base, la pasión nuestro motor y el vino la expresión de nuestro arte&quot;.<br></br><br></br>
Para marcar este hito y celebrar que VIK Winery obtuvo el cuarto lugar en World&#39;s Best Vineyards 2022,
hemos seleccionado nuestra edición VIK 2018, calificada con 98 puntos por James Suckling. <br></br><br></br>
Esta mezcla única de 75% Cabernet Sauvignon y 25% Cabernet Franc, fue elaborada bajo la atenta
mirada del Enólogo Jefe Cristián Vallejo y envejecida durante 26 meses en barricas de roble francés.
Producido de manera sustentable en el “Valle de Oro” de Millahue en Chile, encarna un vino dinámico,
elegante, puro, pero tenso y vibrante. VIK 2018 representa la expresión más auténtica y refinada de
nuestro terroir.
            </h3>
          </div>
          <div className="image-container" style={{marginTop:'20px'}}>
            <img src={wineContainer} alt="wine" className="right-side-image" style={{width:'550px', marginLeft:'70px', marginBottom:'290px'}} />
          </div>
          
        </main>
        </div>
        <div className="app" >
        <main className="main-content" >
        
          <div className="text-container" style={{marginTop:'0px'}}>
            <h3 >Sea uno de los primeros en obtener su VIK 2018 NFT Limited Edition<br></br><br>
            </br>

                Destacando como una oportunidad única para comprar una edición coleccionable de vinos VIK, esta
cosecha solo se venderá en formato NFT y no se puede encontrar en ninguna tienda ya que la compra
pública minorista no está disponible.<br></br><br></br>
Gracias a nuestra plataforma de intercambio NFT, este activo digital representa un token canjeable, que se
puede canjear por una botella física de VIK 2018, y cuyo monto máximo no se puede copiar ni manipular.<br></br><br></br>
Con ello buscamos ser una viña pionera en nuestro país y en Latinoamérica, de acuerdo con los estándares
de excelencia que caracterizan nuestros productos y el trabajo de todos los que trabajan con nosotros.<br></br><br></br>
El lanzamiento será en nuestro portal nft.vikwines.com y el costo por cada NFT será de 175 MATIC.<br></br><br></br>
Recuerda nuestras palabras, esto, es solo el comienzo. Nuestro objetivo no es limitarnos a un único punto
de venta, sino ser un distribuidor multicanal, llegando a nuevos coleccionistas y entusiastas del vino, tanto
para nuestros clientes globales fieles como para los que están inmersos en esta nueva era digital.<br></br><br></br>
En VIK, hacemos futuro.
            </h3>
          </div>
         
          
        </main>
        </div> </section>}
        {selectedOption === 'PT' &&  <section><div className="app" style={{marginTop:'20px'}}>
      <header className="app-header">
      <a href="https://www.vikwine.com/" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="logo" />
          </a>
        <div style={{ position: 'relative' }}>
            <button className="dropdown-button" onClick={() => setIsOpen(!isOpen)}>
              {selectedOption || 'PT'}
            </button>
            {isOpen && (
              <ul className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
                {options.map((option, index) => (
                  <li key={index} onClick={() => handleOptionSelect(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            )}<button className='header-button' style={{ padding:'10px 45px', borderRadius:'5px', marginLeft:'10px', fontWeight:'500'}} onClick={goToClaimPage}>Claim</button>
          </div>
      </header>
        <main className="main-content">
          <div className="text-container">
            <h1 className="title">VIK</h1>
            <h2 className="subtitle">NFT VIK Chile Winery <br></br>2023 Collection</h2>
            <h3 >
Os tokens não fungíveis (NFTs) parecem estar em toda parte atualmente. De arte e música a tweets e
vídeos, esses ativos digitais fazem parte do futuro do comércio, que ironicamente já está aqui.<br></br><br></br>
Enquanto alguns dizem que são uma tendência dominante, outros acreditam que os NFTs estão aqui para
ficar e que mudarão o investimento para sempre.<br></br><br></br>
Para quem não está tão familiarizado com Tokens Não Fungíveis, em suma, são ativos digitais que
representam elementos tangíveis e intangíveis, neste caso, nosso vinho exclusivo. Cada NFT possui uma
assinatura única que comprova sua autenticidade, aumentando seu apelo em indústrias com alto valor
colecionável. A referida “infungibilidade” implica que não podem ser eliminados ou trocados por outro
bem, ao contrário das criptomoedas, ou dinheiro, que podem ser trocados entre unidades iguais.<br></br><br></br>
As previsões de mercado preveem que os NFTs de luxo podem se tornar um mercado de US$ 56 bilhões
até 2030, e é por isso que esta edição limitada do NFT VIK 2018 faz parte de nossos esforços para
traduzir criatividade e qualidade de alta classe em tudo o que fazemos.
            </h3>
          </div>
          
       
   <WalletInfo/>
        
        </main>
      </div>
      <div className="app" >
        <main className="main-content"  style={{ backgroundColor:'#A32036', padding:'20px', borderRadius:'10px'}}>
        <div className="image-container">
            <img src={wine2} alt="wine" className="right-side-image" style={{width:'500px', marginLeft:'50px', marginTop:'50px'}} />
          </div>
          <div className="text-container">
            <h3 style={{color:'white', marginTop:'50px', marginRight:'50px'}}>
Ao contrário da maioria dos bens digitais, os NFTs criam escassez digital, apresentando-se como bens
irrepetíveis com alto valor intrínseco com base em sua raridade, exclusividade e preço. Essas
características são consistentes com o alto valor que os produtos VIK representam. <br></br><br></br>
Como mostram nossos valores fundamentais, o impulso central da VIK depende da inovação.
Considerando que, em nossos processos de produção ou em nossa abordagem de entrada no mercado, nos
orgulhamos de ser disruptivos e de encontrar novas maneiras de oferecer a você o melhor de nossos
produtos exclusivos.
               
            </h3>
          </div>
          
          
        </main>
        <main className="main-content"  style={{ backgroundColor:'#A32036', padding:'20px', borderRadius:'10px', marginTop:'-20px'}}>
        
          <div className="text-container" style={{marginTop:'20px'}}>
            <h3 style={{color:'white', marginTop:'0px', width:'500px', marginRight:'70px', marginLeft:'50px'}}>
Desta vez, decidimos levar essa abordagem um passo à frente em nossa jornada para incorporar cada vez
mais novas tecnologias como parte de nosso portfólio de produtos, e é por isso que 2023 marca o
lançamento de nosso primeiro NFT by VIK. Afinal, “A ciência e o conhecimento são a nossa base, a
paixão o nosso motor e o vinho a expressão da nossa arte”.<br></br><br></br>
Para marcar este marco e comemorar a conquista do 4º lugar do World’s Best Vineyards 2022 pela VIK
Winery, selecionamos a nossa edição VIK 2018 – avaliada com 98 pontos por James Suckling.<br></br><br></br>
Esta mistura única de 75% Cabernet Sauvignon e 25% Cabernet Franc foi elaborada sob o olhar atento do
Enólogo Chefe Cristián Vallejo e envelhecida por 26 meses em barricas de carvalho francês. Produzido
de forma sustentável no “Vale Dourado” de Millahue, no Chile, representa um vinho dinâmico, elegante,
puro, mas tenso e vibrante. O VIK 2018 representa a expressão mais autêntica e refinada do nosso terroir.
            </h3>
          </div>
          <div className="image-container" style={{marginTop:'20px'}}>
            <img src={wineContainer} alt="wine" className="right-side-image" style={{width:'550px', marginLeft:'70px', marginBottom:'290px'}} />
          </div>
          
        </main>
        </div>
        <div className="app" >
        <main className="main-content" >
        
          <div className="text-container" style={{marginTop:'0px'}}>
            <h3 >

Seja um dos primeiros a obter seu VIK 2018 NFT Limited Edition<br></br><br>
            </br>

Destacando-se como uma oportunidade única de adquirir uma edição colecionável dos vinhos VIK, esta
safra será vendida apenas no formato NFT e não poderá ser encontrada em nenhuma loja, pois não está
disponível para compra pública.<br></br><br></br>
Graças à nossa plataforma de troca NFT, este ativo digital representa um token resgatável, que pode ser
trocado por uma garrafa física de VIK 2018 e cujo valor máximo não pode ser copiado ou adulterado.<br></br><br></br>
Com isso, buscamos ser uma vinícola pioneira em nosso país e na América Latina, de acordo com os
padrões de excelência que caracterizam nossos produtos e o trabalho de todos que trabalham conosco.
O lançamento será em nosso portal nft.vikwines.com e o custo de cada NFT será de 175 MATIC.<br></br><br></br>
Marque nossas palavras, isso é apenas o começo. O nosso objetivo é não nos limitarmos a um único ponto
de venda, pretendendo ser um distribuidor multicanal, alcançando novos colecionadores e apreciadores de
vinho, tanto para os nossos fiéis clientes globais, como para os inseridos nesta nova era digital.<br></br><br></br>
Na VIK, fazemos o futuro.
            </h3>
          </div>
         
          
        </main>
        </div> </section>}
       
      </RainbowKitProvider>
     </WagmiConfig>
  );
}


function App() {
  return (
    <Router>
    
   
          <Routes>
            <Route path="/" element={<App2/>}>
          </Route>
     <Route path="/claim" element={<Claim />} />
      </Routes>

     </Router>
  );
}

export default App;
